import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { motion } from "framer-motion";

const Gazdalkodasi = () => {
  return (
    <Wrapper>
      <div className="kozerdeku-adatok-fo-tarolo">
        <h1 className="kozerdeku-adatok-focim">Gazdálkodási adatok</h1>

        <div className="kozerdeku-adat-tarolo">
          <h2 className="kozerdeku-adat-cim">I. Költségvetés, beszámolók</h2>

          <NavLink
            className="kozerdeku-adat-letoltes-tarolo"
            to="/kozerdeku-adatok/gazdalkodasi/koltsegvetes-beszamolok"
            rel="noreferrer"
          >
            <div className="kozerdeku-adat-letoltes">Megtekintés</div>
          </NavLink>
        </div>
        <div className="kozerdeku-adat-tarolo">
          <h2 className="kozerdeku-adat-cim">II. Közbeszerzés</h2>

          <NavLink
            className="kozerdeku-adat-letoltes-tarolo"
            to="/kozerdeku-adatok/gazdalkodasi/kozbeszerzes"
            rel="noreferrer"
          >
            <div className="kozerdeku-adat-letoltes">Megtekintés</div>
          </NavLink>
        </div>
        <div className="kozerdeku-adat-tarolo">
          <h2 className="kozerdeku-adat-cim">III. Szerződések</h2>

          <NavLink
            className="kozerdeku-adat-letoltes-tarolo"
            to="/kozerdeku-adatok/gazdalkodasi/szerzodesek"
            rel="noreferrer"
          >
            <div className="kozerdeku-adat-letoltes">Megtekintés</div>
          </NavLink>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.main`
  .kozerdeku-adatok-fo-tarolo {
    max-width: 1232px;
    width: 64%;
    margin-top: 250px;

    .loading {
      min-height: 100vh;
    }

    .kozerdeku-adatok-focim {
      margin-bottom: 2%;
      font-size: var(--main-title-font-size);
      font-weight: 800;
    }

    .kozerdeku-adat-tarolo {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      border-radius: 35px;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      padding-left: 8.44%;
      padding-right: 8.44%;
      padding-top: 3%;
      padding-bottom: 3%;
      margin-bottom: 1%;
    }
    .kozerdeku-adat-cim {
      width: 70%;
      font-size: var(--title-font-size);
      font-weight: 800;
    }
    .kozerdeku-adat-letoltes-tarolo {
      background-color: var(--box-color);
      width: 30%;
      border-radius: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2%;
      padding-bottom: 2%;
      transition: all 0.3s;
    }
    .kozerdeku-adat-letoltes-tarolo:hover {
      background-color: var(--box-color-hover);
    }
    .kozerdeku-adat-letoltes {
      font-size: var(--button-font-size);
      color: var(--text-color);
      font-weight: 700;
    }
  }

  @media screen and (max-width: 1024px) {
    .kozerdeku-adatok-fo-tarolo {
      width: 94%;
      margin-top: 2.5rem;

      .kozerdeku-adatok-focim {
        font-size: var(--title-font-size);
        text-align: center;
        margin-bottom: 1.5rem;
      }

      .kozerdeku-adat-tarolo {
        flex-direction: column;
        padding: 1.5rem;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;

        .kozerdeku-adat-cim {
          font-size: var(--small-text-font-size);
          width: 100%;
          text-align: center;
        }

        .kozerdeku-adat-letoltes-tarolo {
          width: 80%;
          margin-top: 1rem;
          padding: 1rem 0;
        }
      }
    }
  }
`;

export default Gazdalkodasi;
